<template>
  <telia-grid>
    <telia-col class="header-wrapper" t-id="confirmation" width="8">
      <telia-heading tag="h2" variant="title-200">
        {{ t("CONFIRMATION_HEADING") }}
      </telia-heading>
      <div class="description-holder">
        <telia-p variant="paragraph-200">
          {{ t("CONFIRMATION_CONTACT") }}
        </telia-p>
      </div>
      <div class="description-holder">
        <telia-p variant="paragraph-200">
          {{ t("CONFIRMATION_FOLLOW") }}
          <telia-link
            variant="text"
            disable-visited
            href="/foretag/mybusiness/support/arenden"
            v-text="t('CONFIRMATION_LINK')"
          ></telia-link>
        </telia-p>
        <telia-p variant="paragraph-200">
          {{ t("CONFIRMATION_CASE_ID") + " " + props.caseId }}
        </telia-p>
        <telia-p variant="paragraph-200" v-if="props.emailSent">
          {{ t("CONFIRMATION_EMAIL_SENT") + " " + props.email }}
        </telia-p>
        <telia-p variant="paragraph-200" v-else>
          {{ t("CONFIRMATION_EMAIL_COULD_NOT_SENT") }}
        </telia-p>
      </div>
    </telia-col>
  </telia-grid>
</template>

<script setup lang="ts">
import { translate } from "@telia/b2b-i18n";

interface Props {
  email: string;
  caseId: string;
  emailSent: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  email: "",
  caseId: "",
  emailSent: false,
});

const t = translate;
</script>

<style lang="scss" scoped>
@import "node_modules/@teliads/components/foundations/breakpoints/variables";
@import "node_modules/@teliads/components/foundations/spacing/variables";
@import "node_modules/@teliads/components/foundations/colors/variables";

.header-wrapper {
  padding-top: $telia-spacing-12;
}

.description-holder {
  margin-top: $telia-spacing-24;
  margin-bottom: $telia-spacing-24;
}
</style>
