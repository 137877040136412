<template>
  <div t-id="b2b-order-m365" data-test-id="b2b-order-m365">
    <b2b-layout
      :heading="t('HEADING')"
      show-back-link
      :back-link-label="t('BACK_TO_PRODUCT_AND_SERVICES')"
      :back-link-href="`/foretag/mybusiness/${scopeId}/bestall/produkter-tjanster`"
      show-organisation-selector
      :selected-organisation="selectedOrganisation?.tscid"
      @organisationSelected="handleSelectOrganisation($event.detail)"
    >
      <M365OrderConfirmation
        v-if="status === 'show_confirmation'"
        :email="user?.mail"
        :case-id="orderResponse?.snowCaseId"
        :email-sent="orderResponse?.emailSuccess"
      />

      <M365Form
        v-if="status !== 'show_confirmation'"
        :status="status"
        :scope-id="scopeId"
        :user-information="user"
        :telia-admin="teliaAdmin"
        @submit="submitForm"
      />
      <telia-grid>
        <div v-if="showMissingEmail" class="missing-email">
          <telia-text-input
            :label="t('EMAIL')"
            required
            @input="setNewEmail($event.target.value)"
          />
          <telia-field-assistive-text v-if="!invalidEmail" v-html="t('EMAIL_IS_MANDATORY')" />
          <telia-field-error v-else v-html="t('EMAIL_IS_INVALID')" />
        </div>
        <div v-if="showMissingMobileNumber" class="missing-mobile-number">
          <telia-text-input
            :label="t('MOBILE_NUMBER')"
            required
            @input="setNewPhoneNumber($event.target.value)"
          />
          <telia-field-assistive-text
            v-if="!invalidPhoneNumber"
            v-html="t('MOBILE_NR_IS_MANDATORY')"
          />
          <telia-field-error v-else v-html="t('MOBILE_NUMBER_IS_INVALID')" />
        </div>
        <div v-if="status !== 'show_confirmation'" class="button-holder">
          <telia-skeleton v-if="status === 'loading'" class="button-skeleton" round />
          <b2x-loading-button
            v-else
            t-id="order-button"
            type="button"
            variant="primary"
            :is-loading="status === 'sending'"
            :loading-label="t('ORDER_SENDING')"
            :disabled="status === 'sending' || teliaAdmin"
            @click="submitForm()"
          >
            <span v-if="status !== 'sending'">{{ t("ORDER_BUTTON") }}</span>
          </b2x-loading-button>
        </div>
        <div class="notification-container">
          <telia-notification
            v-if="status === 'send_error'"
            heading-tag="h4"
            variant="inline"
            status="information"
            html-aria-live="polite"
            html-role="alert"
          >
            <span slot="heading">
              <telia-visually-hidden>
                {{ t("ERROR_MESSAGES.SEND_ERROR") }}
              </telia-visually-hidden>
              {{ t("ERROR_MESSAGES.SEND_ERROR") }}
            </span>
          </telia-notification>
        </div>
      </telia-grid>
    </b2b-layout>
  </div>
</template>

<script lang="ts">
interface UserInformation {
  scopeId: string;
  firstName: string;
  lastName: string;
  mail: string;
  phoneNumber: string;
}
</script>

<script setup lang="ts">
import { onMounted, ref, computed } from "vue";
import { getLoggedInUserInfo, getOrganizations, isTeliaAdmin } from "@telia/b2b-logged-in-service";
import { getScopeIdOrThrow } from "@telia/b2b-customer-scope";
import { corpOrderM365Service } from "@telia/b2x-rest-client";
import { GetOrganizationResponse } from "@telia/b2x-rest-client/types/generated/services/corp-customer-group-search";
import { OrderM365ResponseBody } from "@telia/b2x-rest-client/types/generated/services/corp-order-m365-service";
import { trackInitiate, trackComplete } from "./helpers/analyticsManager";
import { translate } from "@telia/b2b-i18n";
import { translateSetup } from "./locale";
import { Status } from "./typings";
import M365OrderConfirmation from "./components/M365OrderConfirmation.vue";
import M365Form from "./components/M365Form.vue";

const scopeId = ref("");
const status = ref<Status>("loading");
const organisations = ref<GetOrganizationResponse[]>([]);
const selectedOrganisation = ref<GetOrganizationResponse>();
const user = ref<UserInformation>();
const orderResponse = ref<OrderM365ResponseBody>();
const newEmail = ref<string>("");
const newPhoneNumber = ref<string>("");
const invalidEmail = ref(false);
const invalidPhoneNumber = ref(false);
const t = translate;
const teliaAdmin = ref(false);

onMounted(async () => {
  await translateSetup(["mybusiness"]);

  try {
    trackInitiate();
    await Promise.all([setupUser(), setupOrganisation()]);
    status.value = "idle";
  } catch (error) {
    status.value = "fetch_error";
  }
});

const showMissingEmail = computed(() => {
  return !user.value?.mail && status.value !== "show_confirmation" && status.value !== "loading";
});

const showMissingMobileNumber = computed(() => {
  return (
    !user.value?.phoneNumber && status.value !== "show_confirmation" && status.value !== "loading"
  );
});

async function setupOrganisation(): Promise<void> {
  organisations.value = await getOrganizations();
  if (organisations.value.length === 1) {
    selectedOrganisation.value = organisations.value[0];
  }
}

async function setupUser(): Promise<void> {
  scopeId.value = await getScopeIdOrThrow();
  user.value = (await getLoggedInUserInfo()) as UserInformation;
  teliaAdmin.value = await isTeliaAdmin();
}

function handleSelectOrganisation(organisationTscid: string) {
  selectedOrganisation.value = organisations.value.find(
    (organisation) => organisation.tscid === organisationTscid
  );
}

function setNewEmail(input) {
  invalidEmail.value = false;
  newEmail.value = input;
}

function setNewPhoneNumber(input) {
  invalidPhoneNumber.value = false;
  newPhoneNumber.value = input;
}

async function submitForm() {
  if (!user.value || !selectedOrganisation.value) {
    return;
  }

  const { firstName, lastName, phoneNumber, mail: email } = user.value;
  status.value = "sending";
  const formObject = {
    account: {
      companyName: selectedOrganisation.value.name,
      tscId: selectedOrganisation.value.tscid,
    },
    contact: {
      firstName,
      lastName,
      email: email ?? newEmail.value,
      phoneNumber: phoneNumber ?? newPhoneNumber.value,
    },
  };

  try {
    orderResponse.value = await corpOrderM365Service.OrderM365ControllerService.orderM365UsingPost(
      formObject,
      scopeId.value
    );
    trackComplete();
    status.value = "show_confirmation";
  } catch (e: any) {
    if (e?.body?.fieldErrors) {
      if (e?.body?.fieldErrors?.["contact.email"]) {
        invalidEmail.value = true;
      }
      if (e?.body?.fieldErrors?.["contact.phoneNumber"]) {
        invalidPhoneNumber.value = true;
      }
      status.value = "idle";
    } else {
      status.value = "send_error";
    }
  }
}
</script>

<style lang="scss" scoped>
@import "node_modules/@teliads/components/foundations/spacing/variables";

.missing-email {
  max-width: 42rem;
  margin-bottom: $telia-spacing-24;
}
.missing-mobile-number {
  max-width: 42rem;
}
.button-holder {
  margin-top: $telia-spacing-24;
}
.button-skeleton {
  height: 5rem;
  width: 10rem;
}
.notification-container {
  max-width: 60rem;
  margin-top: $telia-spacing-24;
}
</style>
