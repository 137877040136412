import { track, TeliaEventName } from "@telia/b2x-google-analytics";

const eventName = {
  Initiate: "m365_initiate",
  Complete: "m365_complete",
};

const segment = {
  business_area: "b2b",
  logged_in: true,
  page_segment: "mybusiness",
  page_type: "order",
};

export const trackInitiate = () => {
  track(eventName.Initiate as TeliaEventName, {
    ...segment,
    site_version: undefined,
    agreement_type: undefined,
  });
};

export const trackComplete = () => {
  track(eventName.Complete as TeliaEventName, {
    ...segment,
    site_version: undefined,
    agreement_type: undefined,
  });
};
