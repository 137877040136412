<template>
  <telia-grid>
    <telia-row class="form-container">
      <div class="fetch-error-notification-wrapper">
        <telia-notification
          v-if="status === 'fetch_error'"
          heading-tag="h4"
          variant="inline"
          status="information"
          html-aria-live="polite"
          html-role="alert"
        >
          <span slot="heading">
            <telia-visually-hidden>
              {{ t("ERROR_MESSAGES.FETCH_ERROR") }}
            </telia-visually-hidden>
            {{ t("ERROR_MESSAGES.FETCH_ERROR") }}
          </span>
        </telia-notification>
      </div>
      <telia-col width="8">
        <telia-skeleton v-if="status === 'loading'" class="information-skeleton" />
        <section class="order-information">
          <telia-skeleton v-if="status === 'loading'" class="card-skeleton" />
          <b2x-card t-id="customer-info-card">
            <div class="card-information">
              <telia-heading tag="h2" variant="title-200" class="customer-info-heading">
                {{ t("CUSTOMER_INFORMATION") }}
              </telia-heading>
              <telia-p v-if="teliaAdmin">{{ t("TELIA_ADMIN_USER_INFO") }}</telia-p>
              <telia-p variant="paragraph-100"
                >{{ userInformation?.firstName }} {{ userInformation?.lastName }}</telia-p
              >
              <telia-p variant="paragraph-100">{{ userInformation?.mail }}</telia-p>
              <telia-p variant="paragraph-100"> {{ userInformation?.phoneNumber }}</telia-p>
            </div>
          </b2x-card>
          <div class="description-holder">
            <telia-p variant="paragraph-100">
              {{ t("CURRENT_INFO_TEXT") }}
              <telia-link
                variant="text"
                disable-visited
                :href="`/foretag/mybusiness/${scopeId}/installningar/minprofil`"
                v-text="t('CURRENT_INFO_LINK')"
              ></telia-link>
            </telia-p>
          </div>
        </section>
      </telia-col>
    </telia-row>
  </telia-grid>
</template>

<script setup lang="ts">
import { translate } from "@telia/b2b-i18n";
import { ref, toRefs } from "vue";
import { Status, UserInformation } from "../typings";
import "@telia/b2x-skeleton-input";
import "@telia/b2x-card";
import "@telia/b2x-loading-button";
import "@telia/b2x-spinner";

interface Props {
  status: Status;
  scopeId: string;
  userInformation?: UserInformation;
  teliaAdmin: boolean;
}

const props = defineProps<Props>();
const emit = defineEmits<{ (e: "submit"): void }>();
const { status, scopeId, userInformation } = toRefs(props);

const t = translate;
const submitted = ref(false);
</script>

<style lang="scss" scoped>
@import "node_modules/@teliads/components/foundations/breakpoints/variables";
@import "node_modules/@teliads/components/foundations/spacing/variables";
@import "node_modules/@teliads/components/foundations/colors/variables";

.order-information {
  margin-bottom: $telia-spacing-24;
  width: fit-content;
}

.card-information {
  padding-right: $telia-spacing-32;
  padding-bottom: $telia-spacing-8;
}

.customer-info-heading {
  margin-bottom: $telia-spacing-4;
}

.form-container {
  margin-top: $telia-spacing-24;
}

.description-holder {
  margin-top: $telia-spacing-24;
  margin-bottom: $telia-spacing-24;
}

.card-skeleton {
  height: 12rem;
  width: 32rem;
}

.information-skeleton {
  height: 3rem;
  width: 32rem;
}
.fetch-error-notification-wrapper {
  padding-bottom: $telia-spacing-32;
}
</style>
